import Footer from "../../components/shared/Footer/Footer";
import ContactsForm from "./components/ContactsForm/ContactsForm";
import Title from "./components/Title/Title";

const Contacts = () => {
  return (
    <>
      <Title />
      <ContactsForm />
      <Footer withoutTopSection />
    </>
  );
};

export default Contacts;
