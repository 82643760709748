function LinkedinIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.516 0A3.531 3.531 0 000 3.516v18.976C0 24.414 1.586 26 3.516 26h18.976A3.528 3.528 0 0026 22.492V3.516C26 1.586 24.414 0 22.492 0H3.516zm0 2h18.976C23.336 2 24 2.664 24 3.516v18.976c0 .844-.664 1.508-1.508 1.508H3.516A1.496 1.496 0 012 22.492V3.516C2 2.664 2.664 2 3.516 2zM6 4c-1.102 0-2 .898-2 2 0 1.102.898 2 2 2 1.102 0 2-.898 2-2 0-1.102-.898-2-2-2zm-2 6v12h4V10H4zm6 0v12h4v-5.36c0-1.663.156-3.156 2.156-3.156 1.969 0 1.844 1.79 1.844 3.266V22h4v-5.922C22 12.641 21.281 10 17.383 10c-1.875 0-2.82.75-3.336 1.75H14V10h-4z"
        fill="#1B203C"
      />
    </svg>
  );
}

export default LinkedinIcon;
