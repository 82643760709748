import { Box, Chip, touchRippleClasses, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { l_contacts } from "../../locales";
import ContactsTypography from "../../theme/ContactsTypography";

const ContactsFormChips = ({
  onChange,
}: {
  onChange: (obj: string) => void;
}) => {
  const chips = [
    [
      [l_contacts("contactsForm.chip1"), l_contacts("contactsForm.chip2")],
      [l_contacts("contactsForm.chip3"), l_contacts("contactsForm.chip4")],
    ],
    [
      [l_contacts("contactsForm.chip5"), l_contacts("contactsForm.chip6")],
      [l_contacts("contactsForm.chip7"), l_contacts("contactsForm.chip8")],
    ],
    [
      [l_contacts("contactsForm.chip9"), l_contacts("contactsForm.chip10")],
      [l_contacts("contactsForm.chip11")],
    ],
  ];

  const [selectedChips, setSelectedChips] = useState<string[]>([]);

  return (
    <Box sx={{ marginBottom: "48px" }}>
      <ContactsTypography
        variant="body2"
        sx={{ color: "rgba(0, 0, 0, 0.38)", marginBottom: "24px" }}
      >
        {l_contacts("contactsForm.subtitle")}
      </ContactsTypography>
      {chips.map((chipRow, i) => (
        <Stack
          key={chipRow[i] + "row"}
          sx={{
            // gap: "16px",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          {chipRow.map((chipCouple, i) => (
            <Stack
              key={chipCouple[i] + "couple"}
              sx={{
                // gap: "16px",
                flexDirection: { xs: "row" },
                flexWrap: "wrap",
              }}
            >
              {chipCouple.map((chip) => (
                <Chip
                  key={chip}
                  sx={{
                    marginRight: "16px",
                    marginBottom: "16px",
                    padding: "8px 16px",
                    transition: (theme) =>
                      theme.transitions.create(["background-color", "color"]),
                    backgroundColor: selectedChips.includes(chip)
                      ? "rgb(232, 83, 34)"
                      : "rgba(232, 83, 34, 0.06)",
                    color: selectedChips.includes(chip) ? "white" : "black",
                    borderRadius: "24px",
                    width: "fit-content",
                    typography: "body1",
                    fontWeight: 400,
                    boxShadow: "unset !important",
                    outline: "1px solid transparent",
                    ":hover": {
                      backgroundColor: selectedChips.includes(chip)
                        ? "rgba(232, 83, 34, .86)"
                        : "rgba(232, 83, 34, 0.12)",
                    },
                    ":focus-visible": {
                      backgroundColor: selectedChips.includes(chip)
                        ? "rgba(232, 83, 34, .86)"
                        : "rgba(232, 83, 34, 0.12)",

                      outlineColor: "rgb(232, 83, 34)",
                    },
                    [`& .${touchRippleClasses.child}`]: {
                      color: "rgb(232, 83, 34)",
                    },
                    span: {
                      padding: 0,
                    },
                  }}
                  onClick={() => {
                    setSelectedChips((prev) => {
                      const newState = prev.includes(chip)
                        ? prev.filter((prevChips) => prevChips !== chip)
                        : [...prev, chip];
                      onChange(newState.join(", "));
                      return newState;
                    });
                  }}
                  label={chip}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      ))}
    </Box>
  );
};

export default ContactsFormChips;
