import { Box, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { z } from "zod";
import ButtonWithUnderline from "../../../../components/shared/ButtonWithUnderline";
import { l_contacts } from "../../locales";
import ContactsTypography from "../../theme/ContactsTypography";
import ContactsTable from "../ContactsTable/ContactsTable";
import ContactsFormChips from "./ContactsFormChips";
import TextFieldWithAnimatedLine from "./TextFieldWithAnimatedLine";
import NiceModal from "@ebay/nice-modal-react";
import ResponsiveSnackbar from "../../../not_found_page/components/ResponsiveSnackbar";
import ButtonRoundArrowIcon from "../../theme/ButtonRoundArrowIcon";

const nameValidator = z.string().min(1);
const emailValidator = z.string().email();
const messageValidator = z.string().min(20);

// NiceModal.register("ResponsiveSnackbar", ResponsiveSnackbar);

const ContactsForm = () => {
  const theme = useTheme();
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));
  const [messageObject, setMessageObject] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isValidName, setIsValidName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidMessage, setIsValidMessage] = useState(true);

  const [sentStatus, setSentStatus] = useState<
    "idle" | "sending" | "sent" | "error"
  >("idle");

  const resetTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
    };
  }, []);

  const sendMail = () => {
    const isValidEmail = emailValidator.safeParse(email).success;
    const isValidMessage = messageValidator.safeParse(message).success;
    const isValidName = nameValidator.safeParse(name).success;
    console.log(isValidMessage);

    setIsValidEmail(isValidEmail);
    setIsValidMessage(isValidMessage);
    setIsValidName(isValidName);

    if (isValidEmail && isValidMessage && isValidName) {
      setSentStatus("sending");

      fetch("https://mailer.mabiloft.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "51bjaUCEGz19QWUCdoou4Jd4Hr9B9v6m",
        },
        mode: "cors",
        body: JSON.stringify({
          email: email,
          message: message + `- Sent by ${name}`,
          interests: messageObject
            .split(",")
            .map((interest) => interest.trim()),
        }),
      })
        .then(() => {
          setSentStatus("sent");
          NiceModal.show("ResponsiveSnackbar", {
            message: l_contacts("snackbar.success"),
            status: "success",
          });
          resetTimeoutRef.current = setTimeout(() => {
            setSentStatus("idle");
            setEmail("");
            setMessage("");
            setName("");
          }, 3000);
        })
        .catch((err) => {
          NiceModal.show("ResponsiveSnackbar", {
            message: l_contacts("snackbar.error"),
            status: "error",
          });

          setSentStatus("error");
        });
    }
  };

  return (
    <>
      <NiceModal.Provider>
        <Box sx={{ width: "90%", maxWidth: "1800px", margin: "auto" }}>
          <ContactsTypography
            variant="body1"
            sx={{
              fontWeight: 700,
              marginBottom: "48px",
              marginTop: { xs: 0, sm: "80px", md: 0 },
            }}
          >
            {l_contacts("contactsForm.title")}
          </ContactsTypography>

          <ContactsFormChips onChange={setMessageObject} />

          <Box>
            <TextFieldWithAnimatedLine
              variant="standard"
              value={name}
              type="text"
              placeholder={l_contacts("contactsForm.namePH")}
              size="small"
              onChange={(e) => {
                setName(e.target.value);
              }}
              onFocus={() => setIsValidName(true)}
              onBlur={() => {
                if (name.trim() !== "") {
                  setIsValidName(nameValidator.safeParse(name).success);
                }
              }}
              fullWidth
              disabled={sentStatus === "sending"}
              error={!isValidName}
              helperText={l_contacts("contactsForm.errorNameMsg")}
            />

            <TextFieldWithAnimatedLine
              variant="standard"
              value={email}
              type="email"
              placeholder={l_contacts("contactsForm.mailPH")}
              size="small"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onFocus={() => {
                setIsValidEmail(true);
              }}
              onBlur={() => {
                if (email.trim() !== "") {
                  setIsValidEmail(emailValidator.safeParse(email).success);
                }
              }}
              fullWidth
              disabled={sentStatus === "sending"}
              error={!isValidEmail}
              helperText={l_contacts("contactsForm.errorMailMsg")}
            />

            {/* <TextField
              variant="outlined"
              multiline
              minRows={5}
              value={message}
              type="text"
              placeholder={l_contacts("contactsForm.messagePH")}
              size="small"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              onFocus={() => {
                setIsValidMessage(true);
              }}
              onBlur={() => {
                if (message.trim() !== "") {
                  setIsValidMessage(
                    messageValidator.safeParse(message).success
                  );
                }
              }}
              fullWidth
              disabled={sentStatus === "sending"}
              error={!isValidMessage}
              helperText={l_contacts("contactsForm.errorMessageMsg")}
            /> */}
            <TextFieldWithAnimatedLine
              variant="standard"
              multiline
              value={message}
              type="text"
              placeholder={l_contacts("contactsForm.messagePH")}
              size="small"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              onFocus={() => {
                setIsValidMessage(true);
              }}
              onBlur={() => {
                if (message.trim() !== "") {
                  setIsValidMessage(
                    messageValidator.safeParse(message).success
                  );
                }
              }}
              fullWidth
              disabled={sentStatus === "sending"}
              error={!isValidMessage}
              helperText={l_contacts("contactsForm.errorMessageMsg")}
            />
          </Box>
          <ResponsiveSnackbar id="ResponsiveSnackbar" message="" />
          <Box
            sx={{
              zIndex: 2,
              marginBottom: { lg: "330px", md: "120px" },
              marginTop: { md: "70px", xs: "56px" },
            }}
          >
            <ButtonWithUnderline
              defaultUnderlined
              onClick={sendMail}
              disabled={
                //   - !isValidEmail ||
                //   - !isValidMessage ||
                //   - !isValidName ||
                sentStatus === "sending" || sentStatus === "sent"
              }
              textStyle={{ display: "flex", gap: "4px", alignItems: "center" }}
            >
              <ContactsTypography variant="body1" sx={{ color: "inherit" }}>
                {l_contacts("contactsForm.buttonTxt")}
              </ContactsTypography>
              <ButtonRoundArrowIcon />
            </ButtonWithUnderline>
          </Box>
        </Box>

        {!isOverLg && (
          <Box
            sx={{
              width: "90%",
              margin: "auto",
              marginTop: { md: "120px", xs: "76px" },
            }}
          >
            <ContactsTable />
          </Box>
        )}
      </NiceModal.Provider>
    </>
  );
};

export default ContactsForm;
