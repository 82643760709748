import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      title: "Contatti",
      description:
        "Se vuoi avere informazioni sui nostri eventi o avviare una nuova collaborazione, qui trovi tutti i nostri contatti: mail, numero di telefono, social network e location.",
    },
    title: {
      title1: "Vuoi collaborare",
      title2: "o conoscerci meglio?",
      description:
        "In alternativa possiamo giocare a calcio balilla o fare una partita di ping pong in ufficio, abbiamo anche una macchina per fare le granite. Insomma, contattaci per qualsiasi cosa, no televendite però.",
    },
    contactsTable: {
      title1: "Dove trovarci",
      content1pt1: "Mabiloft s.r.l",
      content1pt2: "via Luigi Cadorna 2, Albignasego,",
      content1pt3: "35020 Padova, Italia",
      title2: "Come contattarci",
      content2pt1: "hello@mabiloft.com",
      content2pt2: "+39 049 4732842",
      title3: "Seguici",
    },
    contactsForm: {
      title: "Compila il form, ti risponderemo al più presto!",
      subtitle: "Vi contatto per...",
      chip1: "UX/UI design",
      chip2: "Sviluppo mobile",
      chip3: "Sviluppo web",
      chip4: "Sviluppo backend",
      chip5: "Workshop",
      chip6: "Consulenza mobile",
      chip7: "Consulenza web",
      chip8: "Manutenzione",
      chip9: "Networking",
      chip10: "Richiesta stage/tirocinio",
      chip11: "Altro",
      namePH: "Il tuo nome",
      mailPH: "La tua email",
      messagePH: "Qual è il tuo messaggio?",
      buttonTxt: "Invia richiesta",
      errorMailMsg: "Inserisci una mail valida",
      errorMessageMsg: "Il tuo messaggio è troppo breve",
      errorNameMsg: "Inserisci un nome",
    },
    snackbar: {
      success: "La mail è stata mandata con successo.",
      error: "C'è stato un errore. Ritenta.",
    },
  },
  en: {
    seo: {
      title: "Contacts",
      description:
        "If you want more infos on our events or start a new partnership, here you can find all of our contacts: mail, phone number, social networks and location.",
    },
    title: {
      title1: "Do you want to partner up",
      title2: "or to know us better?",
      description:
        "Otherwise we can play table football or have a ping-pong match in the office. We have a slush machine, too. Well, contact us for anything, except for telemarketing.",
    },
    contactsTable: {
      title1: "Where to find us",
      content1pt1: "Mabiloft s.r.l",
      content1pt2: "via Luigi Cadorna 2, Albignasego,",
      content1pt3: "35020 Padova, Italy",
      title2: "How to get in touch",
      content2pt1: "hello@mabiloft.com",
      content2pt2: "+39 049 4732842",
      title3: "Follow us",
    },
    contactsForm: {
      title: "Fill out the form, we will get in touch as soon as possible!",
      subtitle: "I am contact you for...",
      chip1: "UX/UI design",
      chip2: "Mobile development",
      chip3: "Web development",
      chip4: "Backend development",
      chip5: "Workshops",
      chip6: "Mobile advice",
      chip7: "Web advice",
      chip8: "Maintenance",
      chip9: "Networking",
      chip10: "Internship/traineeship request",
      chip11: "Other",
      namePH: "Your name",
      mailPH: "Your email",
      messagePH: "What's your message?",
      buttonTxt: "Send request",
      errorMailMsg: "Insert a valid email address",
      errorMessageMsg: "Your message is too short",
      errorNameMsg: "Insert a name",
    },
    snackbar: {
      success: "E-mail successfully sent.",
      error: "An error occurred. Try again.",
    },
  },
};

export const l_contacts = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
