import { Typography, TypographyProps } from "@mui/material";

type ContactsTypographyProps = {
  variant?: "bigtitle" | "title" | "body1" | "body2" | "span";
} & Omit<TypographyProps, "variant" | "color">;

const ContactsTypography: React.FC<ContactsTypographyProps> = ({
  variant,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={
        variant === "span"
          ? "span"
          : variant === "bigtitle"
          ? "h1"
          : variant === "title"
          ? "h4"
          : "p"
      }
      sx={{
        color: "#000",
        ...(variant === "bigtitle"
          ? {
              fontSize: { xl: "96px", lg: "80px", md: "64px", xs: "40px" },
              lineHeight: { xl: "100px", lg: "84px", md: "68px", xs: "44px" },
              fontWeight: 700,
            }
          : variant === "title"
          ? {
              fontSize: { md: "36px", xs: "20px" },
              lineHeight: { md: "44px", xs: "28px" },
              fontWeight: 700,
            }
          : variant === "body1"
          ? {
              fontSize: { md: "24px", xs: "16px" },
              lineHeight: { md: "32px", xs: "20px" },
              fontWeight: 400,
            }
          : // description, button, compila form, googlemaps, mabiloftsrl
          variant === "body2"
          ? {
              fontSize: { md: "20px", xs: "16px" },
              lineHeight: { md: "28px", xs: "20px" },
              fontWeight: 400,
            }
          : // chips, form, table a dx
          variant === "span"
          ? {
              fontSize: "inherit",
              lineHeight: "inherit",
              fontWeight: "inherit",
            }
          : {}),
        ...sx,
      }}
      {...props}
    />
  );
};
export default ContactsTypography;
