import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect, useRef } from "react";
import { l_contacts } from "../../locales";
import ContactsTypography from "../../theme/ContactsTypography";
import ContactsTable from "../ContactsTable/ContactsTable";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Title = () => {
  const theme = useTheme();
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);

  const animationsRef = useRef<Tween[]>([]);
  useEffect(() => {
    animationsRef.current.forEach((anim) => {
      anim.scrollTrigger?.kill();
      anim.kill();
    });

    animationsRef.current[0] = gsap.fromTo(
      ".contacts-blue-lemon-1",
      { x: -50, y: isOverMd ? -60 : -20 },
      {
        scrollTrigger: {
          trigger: ".contacts-blue-lemon-1",
          scrub: 2,
          start: "top 10%",
        },
        x: 0,
        y: 0,
        rotate: "164deg",
      }
    );
    animationsRef.current[1] = gsap.fromTo(
      ".contacts-blue-lemon-2",
      { x: -40, y: -20 },
      {
        scrollTrigger: {
          trigger: ".contacts-orange-circle",
          scrub: 2,
          start: "top 40%",
        },
        x: 0,
        y: 0,
        rotate: "-100deg",
      }
    );
    animationsRef.current[2] = gsap.fromTo(
      ".contacts-orange-circle",
      { x: 10, y: isOverMd ? -40 : -20 },
      {
        scrollTrigger: {
          trigger: ".contacts-orange-circle",
          scrub: 2,
          start: isOverMd ? "top 40%" : "top 15%",
        },
        x: -20,
        y: 0,
      }
    );

    return () => {};
  }, [isOverMd]);

  return (
    <Box
      sx={{
        paddingTop: { md: "200px", xs: "120px" },
        display: { sm: "flex", xs: "block" },
        gap: "16px",
        justifyContent: "space-between",
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        marginBottom: { xs: "0px", md: "120px" },
      }}
    >
      <Box
        sx={{
          width: { md: "60%", xs: "90%" },
          minWidth: "min(505px, 100%)",
          maxWidth: { lg: "650px", xl: "800px" },
        }}
      >
        <ContactsTypography
          variant="bigtitle"
          sx={{ marginBottom: { md: "56px", xs: "16px" } }}
        >
          {l_contacts("title.title1")}
          <br />
          {l_contacts("title.title2")}
        </ContactsTypography>
        <ContactsTypography
          variant="body1"
          sx={{ width: { md: "110%", xs: "100%" } }}
        >
          {l_contacts("title.description")}
        </ContactsTypography>
      </Box>
      <Box
        sx={{
          // backgroundColor: "rgba(203, 102, 220, 0.3)",
          flex: 1,
          position: "relative",
          height: { sm: "auto", xs: "80px" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: { xs: "30px", md: "60px", lg: "50px", xl: "60px" },
            top: "35%",
            left: { xl: "10%", lg: "0px", md: 0, sm: "70%", xs: "50%" },
          }}
          className="contacts-orange-circle"
        >
          <StaticImage
            alt=""
            src="../../images/orange-circle.svg"
            width={100}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            // backgroundColor: "yellow",
            width: { xs: "60px", md: "120px", lg: "80px", xl: "120px" },
            top: { sm: "-5%", xs: "30%" },
            // left: {sm: "40%", xs: "unset" },
            right: { md: "35%", sm: "100%", xs: 0 },
            transform: "rotate(137deg)",
          }}
          className="contacts-blue-lemon-1"
        >
          <StaticImage alt="" src="../../images/blue-lemon.svg" width={140} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: { md: "95px", lg: "70px", xl: "100px" },
            top: "60%",
            right: "10%",
            transform: "rotate(-130deg)",
            display: { md: "block", xs: "none" },
          }}
          className="contacts-blue-lemon-2"
        >
          <StaticImage alt="" src="../../images/blue-lemon.svg" width={140} />
        </Box>
      </Box>
      {isOverLg && (
        <Box sx={{ width: "30%", maxWidth: "400px", minWidth: "350px" }}>
          <ContactsTable />
        </Box>
      )}
    </Box>
  );
};

export default Title;
