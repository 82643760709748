function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.547 0C3.39 0 0 3.39 0 7.547V18.46C0 22.609 3.39 26 7.547 26H18.46C22.609 26 26 22.61 26 18.453V7.547C26 3.39 22.61 0 18.453 0H7.547zm0 2h10.906A5.528 5.528 0 0124 7.547v10.906A5.527 5.527 0 0118.46 24H7.548A5.527 5.527 0 012 18.46V7.548A5.528 5.528 0 017.547 2zM21 4c-.555 0-1 .445-1 1 0 .555.445 1 1 1 .555 0 1-.445 1-1 0-.555-.445-1-1-1zm-8 2c-3.852 0-7 3.148-7 7s3.148 7 7 7 7-3.148 7-7-3.148-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5-5-2.227-5-5 2.227-5 5-5z"
        fill="#1B203C"
      />
    </svg>
  );
}

export default InstagramIcon;
