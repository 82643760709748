import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../components/old/seo_head";
import ContactPage from "../features/contacts";
import { l_contacts } from "../features/contacts/locales";

export default ContactPage;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_contacts("seo.title")}
      description={l_contacts("seo.description")}
      headProps={headProps}
    />
  );
};
