/** @jsxImportSource @emotion/react */
import { Box, Button } from "@mui/material";
import InstagramIcon from "../../images/icons/InstagramIcon";
import LinkedinIcon from "../../images/icons/LinkedinIcon";
import { l_contacts } from "../../locales";
import ContactsTypography from "../../theme/ContactsTypography";

const ContactsTable = () => {
  const btnStyle = {
    backgroundColor: "rgba(232, 83, 34, 0.06)",
    borderRadius: "90px",
    padding: "8px 16px",
    transition: "background 300ms",
    textTransform: "none",
    ":hover": { backgroundColor: "rgba(232, 83, 34, 0.12)" },
    span: { marginLeft: 0, marginRight: "16px" },
  };

  const contactsTableContent = [
    {
      title: l_contacts("contactsTable.title1"),
      content: (
        <>
          <ContactsTypography variant="body1" sx={{ fontWeight: 700 }}>
            {l_contacts("contactsTable.content1pt1")}
          </ContactsTypography>
          <ContactsTypography
            variant="body2"
            sx={{ marginBottom: { md: "8px", xs: "4px" } }}
          >
            {l_contacts("contactsTable.content1pt2")}
            <br />
            {l_contacts("contactsTable.content1pt3")}
          </ContactsTypography>
          <a
            href="https://goo.gl/maps/BP2jeyuyqGTDoP3bA"
            target="_blank"
            css={{ textDecoration: "none", color: "unset" }}
          >
            <ContactsTypography
              variant="body1"
              sx={{
                color: "rgb(235, 104, 61)",
                textDecoration: "underline",
                textDecorationColor: "rgb(235, 104, 61) !important",
                ":hover": {
                  textDecoration: "none",
                },
              }}
            >
              Google Maps
            </ContactsTypography>
          </a>
        </>
      ),
    },
    {
      title: l_contacts("contactsTable.title2"),
      content: (
        <>
          <a
            href="mailto:hello@mabiloft.com"
            css={{
              textDecoration: "none",
              color: "unset",
            }}
          >
            <ContactsTypography
              variant="body2"
              sx={{ ":hover": { textDecoration: "underline" } }}
            >
              {l_contacts("contactsTable.content2pt1")}
            </ContactsTypography>
          </a>
          <a
            href="tel:+390494732842"
            css={{
              textDecoration: "none",
              color: "unset",
            }}
          >
            <ContactsTypography
              variant="body2"
              sx={{ ":hover": { textDecoration: "underline" } }}
            >
              {l_contacts("contactsTable.content2pt2")}
            </ContactsTypography>
          </a>
        </>
      ),
    },
    {
      title: l_contacts("contactsTable.title3"),
      content: (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column", lg: "row" },
            gap: "16px",
            paddingTop: "8px",
          }}
        >
          <Button
            startIcon={<InstagramIcon />}
            sx={{ ...btnStyle }}
            href="https://www.instagram.com/mabiloft/?hl=en"
            target={"_blank"}
          >
            <ContactsTypography variant="body2">Instagram</ContactsTypography>
          </Button>
          <Button
            startIcon={<LinkedinIcon />}
            sx={{ ...btnStyle }}
            href="https://it.linkedin.com/company/mabiloft"
            target={"_blank"}
          >
            <ContactsTypography variant="body2">Linkedin</ContactsTypography>
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <Box
      sx={{
        flexShrink: 0,
        display: "flex",
        flexDirection: { xs: "column", md: "row", lg: "column" },
        justifyContent: "space-between",
        gap: { lg: "30px", xs: "16px" },
        marginBottom: { md: "200px", lg: 0, xs: "120px" },
      }}
    >
      {contactsTableContent.map((sect, i) => (
        <Box key={sect.title} sx={{}}>
          <ContactsTypography variant="title" sx={{ marginBottom: "8px" }}>
            {sect.title}
          </ContactsTypography>
          {sect.content}
        </Box>
      ))}
    </Box>
  );
};

export default ContactsTable;
